.footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 170px;
  margin: 50px -30px 0;
  padding: 5px 30px;
}
@media only screen and (max-width: 991px) {
  .footer {
    display: block;
    height: auto;
    margin: 50px -20px 0;
    padding: 30px 20px;
  }
}
.footer_landing {
  margin-top: 0;
}
.footer__content {
  color: #fff;
}
.footer__content.footer__content_right {
  line-height: 40px;
  font-size: 12px;
  text-align: right;
}
@media only screen and (max-width: 991px) {
  .footer__content {
    line-height: 1.22;
    font-size: 14px;
    text-align: center;
  }
  .footer__content.footer__content_right {
    line-height: 1.5;
    text-align: center;
  }
}
.footer__content > a {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 auto;
  color: #fff;
  text-decoration: underline;
}
@media only screen and (max-width: 991px) {
  .footer__content > a {
    width: auto;
    margin: 20px 0;
  }
}
.footer__content > a:hover {
  text-decoration: none;
}
.footer__logo {
  margin: 15px 0 0;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
}
@media only screen and (max-width: 991px) {
  .footer__logo {
    margin: 0;
    text-align: center;
  }
}
.footer__social-networks {
  max-height: 35px;
  margin: 20px 0 0;
}
.footer__social-network {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-position: 50% 50%;
  background-size: contain;
  margin: 0;
  font-size: 0;
  opacity: 0.9;
}
.footer__social-network:hover,
.footer__social-network:focus {
  opacity: 1;
}
.footer__social-network + .footer__social-network {
  margin: 0 0 0 30px;
}
.footer__social-network.footer__social-network_fb {
  background-image: url(/_next/static/files/footer-icon-fb_5e0e87bd3ec40e63788f82b1c019f8cd.svg);
}
.footer__social-network.footer__social-network_instagram {
  background-image: url(/_next/static/files/footer-icon-instagram_ac46d8a83aa7d94316aa2c1c86149088.svg);
}
.footer__social-network.footer__social-network_vk {
  background-image: url(/_next/static/files/footer-icon-vk_e2a66e61ec852cdf75b6d53901674928.svg);
}
a.footer__email-link {
  margin: 20px 0 0;
  font-size: 18px;
}
.footer__copyright {
  margin-left: 20px;
}
@media only screen and (max-width: 991px) {
  .footer__copyright {
    display: block;
    margin: 10px 0 0;
  }
}
