* {
  -webkit-locale: auto;
  white-space: normal;
}
p {
  margin: 0;
}
@media only screen and (max-width: 991px) {
  #app {
    font-size: 14px;
  }
}
#app h1,
#app h2 {
  font-weight: 800;
}
@media only screen and (max-width: 991px) {
  #app h1 {
    font-size: 20px;
  }
}
#app label:not(.ant-checkbox-wrapper) {
  font-weight: 600;
}
#app .ant-modal {
  padding-bottom: 0;
}
#app .ant-modal-header {
  padding: 50px 0 0;
  border-bottom: 0;
  text-align: center;
}
#app .ant-modal-title {
  font-size: 22px;
  font-weight: 800;
}
@media only screen and (max-width: 991px) {
  #app .ant-modal-title {
    font-size: 18px;
  }
}
#app .ant-modal-body {
  margin: 40px 0 20px;
  padding: 0 20px;
}
@media only screen and (max-width: 991px) {
  #app .ant-modal-body {
    margin: 30px 0 10px;
  }
}
#app .ant-modal-body .ant-form-item:last-child {
  margin-bottom: 0;
}
#app .ant-modal-body p {
  text-align: center;
  font-size: 14px;
}
#app .ant-modal-footer {
  padding: 20px;
  border-top: 0;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  #app .ant-modal-footer button {
    width: 100%;
  }
}
#app .ant-modal-footer button + button {
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 991px) {
  #app .ant-modal-footer button + button {
    margin: 15px 0 0;
  }
}
#app .ant-modal-close-icon {
  padding-top: 14px;
  color: #302F33;
  font-size: 28px;
  vertical-align: baseline;
  vertical-align: initial;
}
#app textarea.ant-input {
  padding: 15px;
  background-color: #F7F7F9;
}
#app .ant-layout-content {
  min-width: 570px;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  #app .ant-layout-content {
    min-width: auto;
    width: 100%;
  }
}
#app .ant-layout-content > .ant-skeleton {
  padding: 45px;
  background: #fff;
  border-radius: 5px;
}
@media only screen and (max-width: 991px) {
  #app .ant-layout-content > .ant-skeleton {
    padding: 30px 20px;
  }
}
#app .ant-layout-content > .ant-skeleton > .ant-skeleton-content {
  display: block;
  width: 900px;
  margin: auto;
}
#app .ant-layout-sider {
  margin: 0 0 0 20px;
}
@media only screen and (max-width: 1100px) {
  #app .ant-layout-sider {
    -webkit-flex: 0 0 330px !important;
            flex: 0 0 330px !important;
    max-width: 330px !important;
    min-width: 330px !important;
  }
}
#app .ant-layout-sider-collapsed {
  display: none;
}
#app .ant-card-body > h4 {
  margin-top: 20px;
}
#app .ant-skeleton .ant-skeleton-title {
  height: 24px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  #app .ant-skeleton .ant-skeleton-content {
    width: 100% !important;
  }
  #app .ant-skeleton .ant-skeleton-content * {
    width: 100% !important;
  }
}
#app .ant-form-item {
  margin-bottom: 30px;
}
#app .ant-form-item .ant-form-item {
  margin-bottom: 0;
}
#app .ant-form-item-label {
  padding-bottom: 5px;
}
#app .ant-form-item-label > label {
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  #app .ant-form-item-label > label {
    font-size: 12px;
  }
}
#app .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
#app .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  content: '*';
}
#app .ant-btn-lg {
  font-size: 16px;
}
#app .ant-btn-block {
  padding-left: 0;
  padding-right: 0;
}
#app .ant-btn-primary:not([disabled]):hover,
#app .ant-btn-primary:not([disabled]):focus {
  background: #5CB4BD;
  border-color: #5CB4BD;
}
#app .ant-btn-background-ghost:not([disabled]):hover,
#app .ant-btn-background-ghost:not([disabled]):focus {
  background: #7BA7AC !important;
  color: #fff;
  border-color: #7BA7AC;
}
#app .ant-btn-link {
  padding: 0;
  border: none;
  font-weight: 500;
}
#app .ant-btn-link:not([disabled]):hover,
#app .ant-btn-link:not([disabled]):focus {
  color: #5CB4BD;
}
#app .ant-btn-dangerous.ant-btn-primary:not([disabled]):hover,
#app .ant-btn-dangerous.ant-btn-primary:not([disabled]):focus {
  background: #FF4234;
  border-color: #FF4234;
}
#app .ant-btn-dangerous.ant-btn-background-ghost:not([disabled]):hover,
#app .ant-btn-dangerous.ant-btn-background-ghost:not([disabled]):focus {
  background: #FF4234 !important;
  color: #fff;
  border-color: #FF4234;
}
#app .ant-btn-dangerous.ant-btn-link:not([disabled]):hover,
#app .ant-btn-dangerous.ant-btn-link:not([disabled]):focus {
  color: #FF4234;
}
#app .ant-btn[disabled] {
  background: #F2F2F2 !important;
}
#app .ant-input {
  border: none;
  background-color: #F7F7F9;
  padding: 15px;
  line-height: 1;
}
@media only screen and (max-width: 991px) {
  #app .ant-input {
    font-size: 14px;
  }
}
#app .ant-input-affix-wrapper {
  padding: 15px;
}
#app .ant-input-affix-wrapper .ant-input-suffix {
  color: #9C9AA6;
}
#app .ant-input-affix-wrapper input.ant-input {
  padding: 0;
  height: auto;
}
#app .ant-picker-suffix {
  color: #9C9AA6;
}
#app .ant-picker-focused {
  border: 1px solid #9C9AA6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
#app .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner {
  background: #F7F7F9;
}
#app .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border: none;
  border-radius: 3px;
}
#app .ant-checkbox-inner::after {
  width: 8px;
  height: 13px;
  top: 45%;
  left: 28%;
}
#app .ant-select-selector,
#app .ant-input-affix-wrapper,
#app .ant-picker,
#app .ant-input {
  padding: 15px;
  height: 50px;
  background-color: #F7F7F9;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
#app .ant-select-selector:hover,
#app .ant-input-affix-wrapper:hover,
#app .ant-picker:hover,
#app .ant-input:hover {
  border: 1px solid #DCDCDC;
}
#app .ant-select-selector:focus,
#app .ant-input-affix-wrapper:focus,
#app .ant-picker:focus,
#app .ant-input:focus,
#app .ant-select-selector:focus-within,
#app .ant-input-affix-wrapper:focus-within,
#app .ant-picker:focus-within,
#app .ant-input:focus-within {
  background-color: #fff;
  border: 1px solid #9C9AA6;
}
#app .ant-input-affix-wrapper .ant-input {
  border: none;
}
#app .ant-select {
  padding: 0;
}
#app .ant-select-selector {
  padding: 0;
}
#app .ant-select-single.ant-select-open .ant-select-selection-item {
  color: inherit;
}
#app .ant-select-selection-item {
  line-height: 50px;
  padding-left: 15px;
  padding-right: 15px;
}
#app .ant-select-dropdown {
  border: 1px solid #9C9AA6;
  padding: 0;
}
#app .ant-select-item-option {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
}
#app .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
}
#app .ant-rate {
  color: #7BA7AC;
  font-size: 16px;
  line-height: 16px;
}
#app .ant-rate .ant-rate-star {
  margin: 0;
}
#app .ant-rate .ant-rate-star + .ant-rate-star {
  margin-left: 2px;
}
#app .ant-rate-disabled .ant-rate-star {
  pointer-events: none;
}
#app .ant-upload-text-icon {
  display: inline-block;
}
#app .ant-upload-list:not(:empty) {
  margin: 0 0 -20px;
}
#app .ant-upload-list .ant-upload-animate-enter,
#app .ant-upload-list .ant-upload-animate-leave,
#app .ant-upload-list .ant-upload-animate-inline-enter,
#app .ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation: none;
          animation: none;
}
#app .ant-upload-list .file-icon {
  display: block;
  width: 20px;
  height: 22px;
  background: url(/_next/static/files/file-uploaded_71630ba969542df1b0de0f97cfced3de.svg);
}
#app .ant-upload-list .remove-icon {
  display: block;
  float: right;
  width: 15px;
  height: 15px;
  background: url(/_next/static/files/close-filled_424956aa9d88d31a43c6bf19e11e387c.svg);
  -webkit-filter: invert(69%) sepia(33%) saturate(284%) hue-rotate(138deg) brightness(87%) contrast(84%);
          filter: invert(69%) sepia(33%) saturate(284%) hue-rotate(138deg) brightness(87%) contrast(84%);
}
#app .ant-upload-list .remove-icon:hover,
#app .ant-upload-list .remove-icon:focus {
  -webkit-filter: invert(73%) sepia(42%) saturate(425%) hue-rotate(137deg) brightness(83%) contrast(86%);
          filter: invert(73%) sepia(42%) saturate(425%) hue-rotate(137deg) brightness(83%) contrast(86%);
}
#app .ant-upload-list-item {
  margin: 20px 0;
}
#app .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: none;
}
#app .ant-upload-list-item-name {
  width: auto;
  line-height: 22px;
  padding: 0 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
#app .ant-pagination {
  text-align: center;
}
#app .ant-pagination:not(.mini) > * {
  line-height: 50px;
}
#app .ant-pagination:not(.mini) .ant-pagination-prev,
#app .ant-pagination:not(.mini) .ant-pagination-next,
#app .ant-pagination:not(.mini) .ant-pagination-item {
  margin: 0;
  background-color: #F7F7F9;
  border: none;
  -webkit-transition: none;
  transition: none;
}
#app .ant-pagination:not(.mini) .ant-pagination-prev:not([aria-disabled]):hover,
#app .ant-pagination:not(.mini) .ant-pagination-next:not([aria-disabled]):hover,
#app .ant-pagination:not(.mini) .ant-pagination-item:not([aria-disabled]):hover,
#app .ant-pagination:not(.mini) .ant-pagination-prev:not([aria-disabled]):focus,
#app .ant-pagination:not(.mini) .ant-pagination-next:not([aria-disabled]):focus,
#app .ant-pagination:not(.mini) .ant-pagination-item:not([aria-disabled]):focus {
  line-height: 48px;
  border: 1px solid #7BA7AC;
}
#app .ant-pagination:not(.mini) .ant-pagination-prev {
  margin: 0 30px 0 0;
}
#app .ant-pagination:not(.mini) .ant-pagination-next {
  margin: 0 0 0 30px;
}
#app .ant-pagination:not(.mini) .ant-pagination-item-active {
  background-color: #7BA7AC;
}
#app .ant-pagination:not(.mini) .ant-pagination-item-active a {
  color: #fff;
}
#app .ant-pagination:not(.mini) .ant-pagination-item-link {
  border: none;
  background-color: #F7F7F9;
}
#app .ant-pagination:not(.mini) .ant-pagination-item + .ant-pagination-item {
  margin-left: 10px;
}
#app .ant-pagination:not(.mini) .ant-pagination-jump-next {
  margin: 0 10px;
}
#app .ant-tooltip .ant-tooltip-inner {
  font-size: 14px;
}
#app [class$=indeterminate] [class$=checkbox-inner]::after {
  content: '\2013';
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  line-height: 23px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  border-radius: 3px;
  -webkit-transform: none;
          transform: none;
}
#__next {
  min-height: 100vh;
}
#__next > .ant-spin-nested-loading {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
#__next > .ant-spin-nested-loading > div:not([class]):first-child {
  z-index: 2000;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.5);
}
#__next > .ant-spin-nested-loading > div:not([class]):first-child .ant-spin.global-spin {
  min-height: 100vh;
  max-height: 100vh;
  z-index: 2001;
}
#__next > .ant-spin-nested-loading > div:not([class]):first-child .ant-spin.global-spin > .anticon {
  margin: -20px;
  color: #cb5042;
  font-size: 40px;
}
#__next > .ant-spin-nested-loading > .ant-spin-blur {
  opacity: 1;
}
#__next > .ant-spin-nested-loading > .ant-spin-blur::after {
  display: none;
}
#__next > .ant-spin-nested-loading > .ant-spin-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0 30px;
}
@media only screen and (max-width: 991px) {
  #__next > .ant-spin-nested-loading > .ant-spin-container {
    padding: 0 20px;
  }
}
.form__row.form__row_two-items {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .form__row.form__row_two-items {
    display: block;
  }
}
#app .form__row.form__row_last .ant-row,
#app .form__row.form__row_last .ant-form-item {
  margin-bottom: 0;
}
.form__item.form__item_small {
  width: 300px;
}
@media only screen and (max-width: 991px) {
  .form__item.form__item_small {
    width: 100%;
  }
}
.form__footer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 60px 0 0;
}
@media only screen and (max-width: 991px) {
  .form__footer {
    display: block;
    margin: 40px 0 0;
  }
  .form__footer .ant-btn {
    display: block;
    width: 100%;
    margin: 15px 0 0;
  }
}
.form__footer.form__footer_only-child {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.form__control + .form__control {
  margin-left: 20px;
}
@media only screen and (max-width: 991px) {
  .form__control + .form__control {
    margin-left: 0;
  }
}
.search-results {
  margin: 20px 0 0;
}
.search-results > *:last-child {
  margin-bottom: 0;
}
.search-results .ant-pagination {
  padding: 50px 0 0;
  border-top: 1px solid #DCDCDC;
}
