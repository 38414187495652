.header {
  background-color: transparent;
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .header {
    height: 50px;
    margin: 0 -20px 20px;
    padding: 0;
  }
}
.header__wrapper {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  line-height: 25px;
}
.header__wrapper > * {
  -webkit-align-self: center;
          align-self: center;
}
@media only screen and (max-width: 991px) {
  .header__wrapper {
    width: 100%;
  }
}
.header__logo {
  margin: -5px 20px 0 0;
  -webkit-object-fit: contain;
          object-fit: contain;
}
@media only screen and (max-width: 991px) {
  .header__logo {
    width: 100px;
    height: 50px;
    margin: 0;
    padding: 0 10px;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
  }
}
.header .ant-btn-link {
  color: #302F33;
  font-size: 16px;
}
@media only screen and (max-width: 991px) {
  .header .ant-btn-link {
    font-size: 13px;
  }
}
.header .ant-menu:not(.ant-menu-dark) {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  border-bottom: 0;
  background: transparent;
  padding-top: 5px;
}
@media only screen and (max-width: 991px) {
  .header .ant-menu:not(.ant-menu-dark) {
    -webkit-flex: initial;
            flex: initial;
    margin: 0 0 0 auto;
    padding: 0;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1145px) {
  .header .ant-menu:not(.ant-menu-dark) {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: right;
  }
}
.header .ant-menu:not(.ant-menu-dark) > .ant-menu-item {
  border-bottom: 3px solid transparent;
}
@media only screen and (max-width: 991px) {
  .header .ant-menu:not(.ant-menu-dark) > .ant-menu-item {
    margin-bottom: 2px;
  }
}
.header .ant-menu:not(.ant-menu-dark) > .ant-menu-item,
.header .ant-menu:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #302F33;
}
.header .ant-menu:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: 3px solid #FE6054;
}
.header .ant-menu:not(.ant-menu-dark) .ant-menu-item-selected,
.header .ant-menu:not(.ant-menu-dark) .ant-menu-item-selected:hover {
  border-bottom: 3px solid #FE6054;
}
.header .ant-menu:not(.ant-menu-dark) .ant-menu-overflowed-submenu {
  margin: 5px 0 0;
  padding: 0;
  border: none !important;
}
@media only screen and (min-width: 992px) {
  .header .ant-menu:not(.ant-menu-dark) .ant-menu-overflowed-submenu {
    margin: 0 0 -2px;
  }
}
.header .ant-menu:not(.ant-menu-dark) .ant-menu-overflowed-submenu .anticon-menu {
  margin: 0;
  font-size: 30px;
}
.header.header_landing {
  height: 80px;
  margin: 0 -30px;
  padding: 0 30px;
  background: #5CB4BD;
}
@media only screen and (max-width: 991px) {
  .header.header_landing {
    height: 50px;
    margin: 0 -20px;
    padding: 0;
  }
}
.header.header_landing .header__wrapper {
  width: 1155px;
  margin: auto;
}
@media only screen and (max-width: 1154px) {
  .header.header_landing .header__wrapper {
    width: 100%;
  }
}
.header.header_landing .header__logo {
  width: 165px;
  height: 80px;
  margin: 0;
  padding: 0 25px;
  background: #fff;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}
@media only screen and (max-width: 991px) {
  .header.header_landing .header__logo {
    width: 100px;
    height: 50px;
    padding: 0 10px;
  }
}
.header.header_landing .ant-menu {
  margin: 0 0 0 auto;
  padding: 0;
}
.header.header_landing .ant-menu .ant-menu-item a {
  color: #fff;
}
.header.header_landing .ant-menu .ant-menu-overflowed-submenu {
  color: #fff;
}
.header.header_landing .ant-menu .ant-menu-overflowed-submenu .anticon-menu {
  color: #fff;
}
.header.header_landing .ant-btn-link {
  color: #fff;
}
.header.header_landing .ant-btn-link:hover,
.header.header_landing .ant-btn-link:focus {
  color: #FE6054 !important;
}
.header.header_landing .user-menu__name {
  color: #fff;
}
.header.header_landing .user-menu__vertical-divider {
  margin: 0 10px;
  background: #fff;
}
@media only screen and (max-width: 991px) {
  .header.header_landing .user-menu__vertical-divider {
    margin: 0 5px;
  }
}
.user-menu {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
@media only screen and (max-width: 1154px) {
  .user-menu {
    margin: 5px 10px 0;
  }
}
@media only screen and (min-width: 992px) {
  .user-menu {
    margin: 5px 0 0 25px;
  }
  .user-menu:not(.ant-dropdown-trigger) {
    min-width: 205px;
  }
}
.user-menu__link-button {
  height: 17px;
  line-height: 17px;
  border: none;
}
.user-menu__vertical-divider {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 1px;
  height: 17px;
  margin: 0 20px;
  background: #302F33;
}
@media only screen and (max-width: 991px) {
  .user-menu__vertical-divider {
    width: 0;
    margin: 0 5px;
  }
}
.user-menu__name {
  font-size: 14px;
  font-weight: 500;
}
@media only screen and (max-width: 1154px) {
  .user-menu__name {
    display: none;
  }
}
.user-menu__avatar {
  margin: 0 0 0 15px;
}
@media only screen and (max-width: 991px) {
  .user-menu__avatar {
    margin: 0;
  }
}
.user-menu__dropdown {
  width: 200px;
  -webkit-filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));
}
.user-menu__dropdown > .ant-menu {
  padding: 10px 0;
}
.user-menu__dropdown > .ant-menu > .ant-menu-item {
  height: 100%;
  margin: 0;
  padding: 0;
}
.user-menu__dropdown > .ant-menu > .ant-menu-item a {
  display: block;
  line-height: 1.25;
  padding: 10px 30px;
}
.user-menu__dropdown > .ant-menu > .ant-menu-item a span {
  position: relative;
  font-size: 14px;
}
.user-menu__dropdown > .ant-menu > .ant-menu-item-active a span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  background: #DCDCDC;
}
